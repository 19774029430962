import React from "react"
import HeaderV2 from "../../componentsv2/headerv2/headerv2"
import Hero from "../../microcomponents/hero/hero"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import HowitWorks from "../../microcomponents/howitworks/howitworks"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import TechStacks from "../../microcomponents/techstacks/techstacks"
import ClientResults from "../../microcomponents/clientresults/clientresults"

export default function CROAgencyV2() {
  return (
    <div className={`control`}>
      <HeaderV2 version="v2" />
      <Hero
        version="v2"
        heading={`Get 3 Custom A/B Split Tests`}
        className={`blueeclipse`}
        landingPageNeilPatel={true}
        description="
            <p>Leverage our prediction engine that captures <br class='hidedesktop hideipad hidemobile'/> winning A/B tests from 78,000 websites. Get <br class='hidetablet hideipad hidemobile'/> 3 Custom A/B tests hand-selected by our <br class='hideipad hidemobile'/> team to boost your conversions.</p>
        "
        heroImage={`heroimagev2.webp`}
        ctaText="Get Your 3 Custom A/B Tests"
        video={`//res.cloudinary.com/spiralyze/video/upload/spiralyze2022/VIDEOS/Hero_animation_Single_screen_version.mp4`}
        sourceFolder={`homepage`}
        cloudinaryPath={`https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyze2022`}
      />
      <ClientLogos version="v2 gray-bg" />
      <HowitWorks
        version="v2"
        section1Description="<p>Spiralyze scrapes A/B test data from<br /> the 78,000 sites that actively test to<br /> learn what wins. Machine learning<br /> uses that data to predict what wins on<br /> your sites.</p><p>Get 3X higher win rates than legacy<br/> A/B testing.</p>"
        section1Image="howitworksimage1.webp"
        section2Cta="Full-Service"
        section2Description="<p>Design, copy, dev, QA, analytics, and<br/> PM. All software included.<br/> First test live in just 2 weeks.<br/> All implementation handled by a<br class='hidemobile'/> team of 170 A/B testing specialists.</p>"
        section3Cta="Pricing"
      />
      <TopPredictions version="v2" />
      <TechStacks version="v2" heading="Experience across<br/> tech stacks" />

      <ClientResults version="v2" />
    </div>
  )
}
