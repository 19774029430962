import * as React from "react"
import Seo from "../components/seo"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import Layout from "../components/layout2023"
import CROAgencyV2 from "../components/sass-lp/cro-agencyv2"

const Customers = () => {
  return (
    <Layout nav={"hidden"} parentClass={`newtheme2024`}>
      <Seo
        title="CRO Agency for B2B Companies | Guaranteed Results | Spiralyze"
        canonical={`https://spiralyze.com/`}
      />
      <CROAgencyV2 />
      <FooterV2 version="v2" />
    </Layout>
  )
}

export default Customers
